<template>
  <div class="bg-white" style="z-index: 999999">
    <!-- <top-line-header-comp /> -->
    <!-- Inititate Desktop Nav -->
    <!-- Top Nav -->
    <div class="px-4">
      <nav
        class="navbar navbar-expand-lg navbar-light d-md-flex d-sm-non d-none"
      >
        <div
          class="
            navbar-left navbar-collapse
            collapse
            order-1 order-md-0
            dual-collapse2
          "
        >
          <div class="navbar-brand mr-auto">
            <router-link to="/" class="">
              <img
                src="/img/logo_selcare_small.png"
                height="45"
                alt=""
                loading="lazy"
              />
            </router-link>
          </div>
        </div>
        <div class="navbar-collapse collapse mx-auto order-0 middle-nav">
          <ul
            class="navbar-nav mx-auto small fs-responsive"
            style="font-weight: bold"
          >
            <!-- <li class="nav-item mr-1" :class="{ active: $route.path == '/' }">
              <router-link to="/" class="nav-link">Home</router-link>
            </li> -->
            <li
              class="nav-item mr-1"
              :class="{ active: $route.path == '/home-nursing/booking' }"
            >
              <router-link class="nav-link" to="/home-nursing/booking"
                >Home Nursing</router-link
              >
            </li>
            <li class="nav-item mr-1">
              <a v-if="!currentCustomer" class="nav-link" target="_blank" href="https://jovianselcare.com/">Pharmacy</a>
              <router-link v-else class="nav-link" to="/customer/jovianselcare-generate-token">Pharmacy</router-link>
            </li>
            <li
              class="nav-item mr-1"
              :class="{ active: ($route.path).includes('/e-prescription') }"
            >
              <router-link
                v-if="this.currentCustomer == null"
                class="nav-link"
                to="/auth/login"
                >Telemedicine /<br />
                E-prescription</router-link
              >
              <router-link v-else class="nav-link" to="/e-prescription/checking"
                >Telemedicine /<br />
                E-prescription</router-link
              >
            </li>
            <li
              class="nav-item mr-1"
              :class="{ active: ($route.path).includes('/clinic-appointment') }"
            >
              <router-link
                class="nav-link"
                v-if="this.currentCustomer == null"
                to="/auth/login"
                >Clinic/Dental<br />
                Appointment</router-link
              >
              <router-link
                v-else
                class="nav-link"
                to="/clinic-appointment/manage"
                >Clinic/Dental<br />
                Appointment</router-link
              >
            </li>
            <li class="nav-item mr-1">
              <a class="nav-link" target="_blank" href="https://secure.selcare.my"
                >Selcare Corporate /<br />
                TPA</a
              >
            </li>
            <!-- <li class="nav-item dropdown mr-1">
                            <router-link to="/store" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Pharmacy</router-link>
                            <div class="dropdown-menu mt-0 pt-1 pb-1">
                                <router-link to="/store/products" class="dropdown-item">Products</router-link>
                            </div>
                        </li> -->

            <!-- <li class="nav-item badge-blue">
                            <router-link class="nav-link" to="/store/home-nursing/apply">Become a Home Nursing</router-link>
                        </li> -->
            <!-- <li class="nav-item dropdown mr-1">
              <router-link class="nav-link" to="/info/about-us">About</router-link>
              <a
                class="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="https://selgatecorporation.com/healthcare/"
                >About Us</a
              >
              <div class="dropdown-menu mt-0 pt-1 pb-1">
                <router-link to="/info/about-nursing" class="dropdown-item"
                  >About Nursing</router-link
                >
              </div>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/feedback">Contact Us</router-link>
              <a
                class="nav-link"
                href="https://selgatecorporation.com/contact-us/"
                >Contact Us</a
              >
            </li> -->
          </ul>
        </div>
        <div
          class="navbar-right navbar-collapse collapse order-3 dual-collapse2"
        >
          <div class="navbar-nav ml-auto">
            <div class="btn-group">
              <span
                class="btn dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                ><i class="fas fa-user-circle"></i
              ></span>
              <div
                v-if="currentCustomer"
                class="dropdown-menu dropdown-menu-right"
              >
                <button
                  class="dropdown-item"
                  type="button"
                  @click="$router.push('/customer/dashboard')"
                >
                  My Dashboard
                </button>
                <button class="dropdown-item" type="button" @click="signOut">
                  Logout
                </button>
              </div>
              <div v-else class="dropdown-menu dropdown-menu-right">
                <button
                  class="dropdown-item"
                  type="button"
                  @click="$router.push('/auth/login')"
                >
                  Log In
                </button>
                <button
                  class="dropdown-item"
                  type="button"
                  @click="$router.push('/auth/signup')"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <!-- <nav
        id="top-nav"
        class="navbar navbar-expand-lg navbar-light"
        v-if="isShowTopNav"
      >
        <div
          class="
            navbar-collapse
            collapse
            w-100
            order-1 order-md-0
            dual-collapse2
          "
        >
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#">English</a>
            </li>
          </ul>
        </div>
        <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
          <ul class="navbar-nav ml-auto">
            <li v-if="!currentCustomer" class="nav-item">
              <router-link to="/auth/login" class="nav-link"
                >Log In</router-link
              >
            </li>
            <li v-if="!currentCustomer" class="nav-item">
              <router-link to="/auth/signup" class="nav-link"
                >Sign Up</router-link
              >
            </li>
            <li v-if="currentCustomer" class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="notiDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="far fa-bell"></i>
              </a>
              <div class="dropdown-menu" aria-labelledby="notiDropdown">
                <a
                  v-if="notifications.length == 0"
                  class="dropdown-item disabled"
                  href="#"
                  >There is no notification yet.</a
                >
                <a
                  v-else
                  v-for="notification in notifications"
                  :key="notification.id"
                  class="dropdown-item disabled"
                  href="#"
                  >{{ notification.content }}</a
                >
              </div>
            </li>
            <li v-if="currentCustomer" class="nav-item">
              <router-link to="/customer/dashboard" class="nav-link">{{
                currentCustomer.user.username
              }}</router-link>
            </li>
            <li v-if="currentCustomer" class="nav-item">
              <a href="#" class="nav-link" @click="signOut">Log Out</a>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/store/cart"
                >My Cart</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/store/cart">
                {{ numberOfItems }}
                <i class="fas fa-shopping-cart ml-2" />
              </router-link>
            </li>
          </ul>
        </div>
      </nav> -->
      <!-- Bottom Nav -->
      <!-- <nav id="bottom-nav" class="navbar navbar-expand-lg navbar-light">
        <div
          style="width: 200px"
          class="
            navbar-left navbar-collapse
            collapse
            order-1 order-md-0
            dual-collapse2
          "
        >
          <div class="navbar-brand mr-auto">
            <router-link to="/" class="">
              <img
                src="/img/logo_selcare_512.png"
                height="45"
                alt=""
                loading="lazy"
              />
            </router-link>
          </div>
        </div>
        <div
          class="
            navbar-collapse
            collapse
            mx-auto
            order-0
            justify-content-center
          "
          style="width: calc(100vw - 400px)"
        >
          <ul
            class="navbar-nav mx-auto fs-responsive"
            style="font-weight: bold"
          >
            <li class="nav-item" :class="{ active: $route.path == '/' }">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <li
              class="nav-item mr-1"
              :class="{ active: $route.path == '/home-nursing/booking' }"
            >
              <router-link class="nav-link" to="/home-nursing/booking"
                >Home Nursing</router-link
              >
            </li>
            <li class="nav-item mr-1">
              <a class="nav-link" href="https://jovianselcare.com/">Pharmacy</a>
            </li>
            <li
              class="nav-item mr-1"
              :class="{ active: $route.path == '/e-prescription/check' }"
            >
              <router-link
                class="nav-link"
                v-if="this.currentCustomer == null"
                to="/auth/login"
                >Telemedicine /<br />
                E-prescription</router-link
              >
              <router-link v-else class="nav-link" to="/e-prescription/checking"
                >Telemedicine /<br />
                E-prescription</router-link
              >
            </li>
            <li
              class="nav-item mr-1"
              :class="{ active: $route.path == '/clinic-appointment/manage' }"
            >
              <router-link
                class="nav-link"
                v-if="this.currentCustomer == null"
                to="/auth/login"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                >Clinic Dental /<br />
                Appointment</router-link
              >
              <router-link
                v-else
                class="nav-link"
                to="/clinic-appointment/manage"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                >Clinic Dental /<br />
                Appointment</router-link
              >
            </li>
            <li class="nav-item mr-1">
              <a class="nav-link" href="https://secure.selcare.my"
                >Selcare Corporate<br />
                / TPA</a
              >
            </li>
          </ul>
        </div>
        <div
          style="width: 200px"
          class="navbar-right navbar-collapse collapse order-3 dual-collapse2"
        >
          <div class="navbar-nav ml-auto">
            <div class="form-inline">
              <input
                class="form-control mr-sm-2 w-100"
                style="font-size: 0.7rem"
                v-model="keywords"
                @keyup.enter="submitSearch"
                type="search"
                placeholder="Search our store"
                aria-label="Search our store"
              />
            </div>
          </div>
        </div>
      </nav> -->

      <!-- Inititate Mobile Nav -->
      <nav id="mobile-nav" class="navbar navbar-light light-blue lighten-4">
        <!-- Navbar brand -->
        <div class="navbar-brand mr-auto">
          <router-link
            to="/"
            data-toggle="collapse"
            data-target="#navbarSupportedContent1"
          >
            <img
              src="/img/logo_selcare_512.png"
              height="45"
              alt=""
              loading="lazy"
            />
          </router-link>
          <!-- <router-link class="text-after-logo text-muted" to="/home-nursing/apply" data-toggle="collapse" data-target="#navbarSupportedContent1">Home Nursing</router-link> -->
        </div>
        <!-- Collapse button -->
        <button
          class="navbar-toggler toggler-example ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent1"
          aria-controls="navbarSupportedContent1"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="dark-blue-text"><i class="fas fa-bars fa-1x"></i></span>
        </button>
        <!-- Cart button -->
        <div class="nav-item border-left">
          <router-link
            class="nav-link pr-0"
            to="/store/cart"
            style="color: rgba(0, 0, 0, 0.5)"
          >
            {{ numberOfItems }}
            <i class="fas fa-shopping-cart ml-2" />
          </router-link>
        </div>
        <!-- Collapsible content -->
        <div
          class="collapse navbar-collapse"
          id="navbarSupportedContent1"
          style="max-height: calc(100vh - 65px); overflow: auto"
        >
          <!-- Links -->
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" :class="{ active: $route.path == '/' }">
              <router-link
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                to="/"
                >Home</router-link
              >
            </li>
            <!-- <li
            class="nav-item"
            :class="{ active: $route.path.includes('/products') }"
          >
            <router-link
              class="nav-link"
              data-toggle="collapse"
              data-target="#navbarSupportedContent1"
              to="/store/products"
              >Products</router-link
            >
          </li> -->
            <li
              class="nav-item"
              :class="{ active: $route.path.includes('/home-nursing/booking') }"
            >
              <router-link
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                to="/home-nursing/booking"
                >Home Nursing</router-link
              >
            </li>
            <li class="nav-item mr-1">
              <a
                v-if="!currentCustomer"
                target="_blank"
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                href="https://jovianselcare.com/"
              >Pharmacy</a>
              <router-link
                v-else
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                to="/customer/jovianselcare-generate-token"
              >Pharmacy</router-link>
            </li>
            <li
              class="nav-item mr-1"
              :class="{ active: $route.path == '/e-prescription/check' }"
            >
              <router-link
                v-if="this.currentCustomer == null"
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                to="/auth/login"
                >Telemedicine /<br />
                E-prescription</router-link
              >
              <router-link
                v-else
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                to="/e-prescription/checking"
                >Telemedicine /<br />
                E-prescription</router-link
              >
            </li>
            <li
              class="nav-item mr-1"
              :class="{ active: $route.path == '/clinic-appointment/manage' }"
            >
              <router-link
                v-if="this.currentCustomer == null"
                class="nav-link"
                to="/auth/login"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                >Clinic/Dental <br />
                Appointment</router-link
              >
              <router-link
                v-else
                class="nav-link"
                to="/clinic-appointment/manage"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                >Clinic/Dental <br />
                Appointment</router-link
              >
            </li>
            <li class="nav-item mr-1">
              <a class="nav-link" target="_blank" href="https://secure.selcare.my"
                >Selcare Corporate<br />
                / TPA</a
              >
            </li>
            <li
              class="nav-item"
              :class="{ active: $route.path.includes('/info/about-us') }"
            >
              <router-link
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                to="/info/about-us"
                >About</router-link
              >
            </li>
            <li
              v-if="!currentCustomer"
              class="nav-item"
              :class="{ active: $route.path.includes('/auth/login') }"
            >
              <router-link
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                to="/auth/login"
                >Login</router-link
              >
            </li>
            <li
              v-if="!currentCustomer"
              class="nav-item"
              :class="{ active: $route.path.includes('/auth/signup') }"
            >
              <router-link
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                to="/auth/signup"
                >Register</router-link
              >
            </li>
            <li
              v-if="currentCustomer"
              class="nav-item"
              :class="{ active: $route.path.includes('/auth/login') }"
            >
              <router-link
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                to="/customer/dashboard"
                >{{ currentCustomer.user.username }}</router-link
              >
            </li>
            <li
              v-if="currentCustomer"
              class="nav-item"
              :class="{ active: $route.path.includes('/auth/signup') }"
            >
              <a
                href="#"
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                @click="signOut"
                >Log Out</a
              >
            </li>
            <li
              class="nav-item"
              :class="{ active: $route.path.includes('/store/cart') }"
            >
              <router-link
                class="nav-link"
                data-toggle="collapse"
                data-target="#navbarSupportedContent1"
                to="/store/cart"
                >My Cart</router-link
              >
            </li>
            <li class="nav-item">
              <div class="form-inline mr-3">
                <input
                  class="form-control mr-sm-2"
                  v-model="keywords"
                  @keyup.enter="submitSearch"
                  type="search"
                  placeholder="Search our store"
                  aria-label="Search our store"
                />
              </div>
            </li>
          </ul>
          <!-- Links -->
        </div>
        <!-- Collapsible content -->
      </nav>
    </div>
  </div>
</template>

<script>
import API from "./../utils/API";
import { mapGetters, mapActions } from "vuex";

import TopLineHeaderComp from "./TopLineHeader";
export default {
  components: {
    TopLineHeaderComp,
  },
  data() {
    return {
      keywords: null,
      notifications: [],
      isShowTopNav: true,
    };
  },
  computed: {
    ...mapGetters(["numberOfItems", "currentCustomer"]),
    // numberOfItems(){
    //     return this.$store.getters.numberOfItems
    // }
  },
  methods: {
    ...mapActions(["signOut"]),
    submitSearch() {
      window.location.href = `/store/products?keywords=${this.keywords}`;
      // this.$router.push(`/products?keywords=${this.keywords}`)
    },
  },
  async mounted() {
    // console.log('customer : ', this.currentCustomer)
    if (this.$route.meta && this.$route.meta.title == "E-prescriptions") {
      this.isShowTopNav = false;
    } else if (this.$route.meta && this.$route.meta.title == "Policy") {
      this.isShowTopNav = false;
    }

    //get notification if currentcustomer
    if (this.currentCustomer) {
      let notiRes = await API.get("/notifications");
      this.notifications = notiRes.data;
    }

    $(".navbar-collapse").on("show.bs.collapse", function () {
      $("body").addClass("navbar-collapsed");
    });

    $(".navbar-collapse").on("hidden.bs.collapse", function () {
      $("body").removeClass("navbar-collapsed");
    });
  },
};
</script>

<style>
/** General */
nav {
  font-family: "Karla", serif;
  /* font-family: "Karla",arial,sans-serif; */
  text-transform: uppercase;
  color: #666;
  letter-spacing: 0.12em;
  font-weight: bold;
  font-size: 14px;
  padding: 30px 30px!important;
}
nav .form-inline input[type="search"] {
  width: 250px;
}
nav .form-inline input:focus {
  border-color: #999;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 0, 0, 0.075);
  outline: 0 none;
}
nav .nav-item.dropdown .dropdown-menu {
  text-transform: initial;
  margin-top: 9px;
}

nav .text-after-logo {
  font-size: 14px;
  margin-left: 20px;
  text-decoration: unset;
  font-weight: bold;
}

nav#bottom-nav .order-0 {
  min-width: 550px;
}
nav#bottom-nav .nav-item.badge-pink {
  border: 1px solid #f3caca;
  border-radius: 11px;
  background-color: #f3caca;
  padding: 0px 5px;
  margin-left: 5px;
}

.middle-nav {
  background: #a91a17;
  padding: 10px 10px;
  border-radius: 10px;
}
.middle-nav .nav-link {
  color: #fff!important;
}

.middle-nav li.nav-item.active::after {
    content: '';
    border-bottom: 3px solid #fff;
    width: 70%;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
}

.fs-responsive {
  font-size: 0.7rem !important;
}

@media (max-width: 1200px) {
  .fs-responsive {
    /* font-size: 0.51rem !important; */
    font-size: 0.7rem !important;
  }
}

@media (max-width: 1024px) {
  .fs-responsive {
    /* font-size: 0.45rem !important; */
    font-size: 0.7rem !important;
  }
}

@media (max-width: 992px) {
  .fs-responsive {
    /* font-size: 0.4rem !important; */
    font-size: 0.7rem !important;
  }
}

@media (max-width: 400px) {
  .fs-responsive {
    /* font-size: 0.4rem !important; */
    font-size: 0.7rem !important;
  }
}

/** For Desktop Size Nav */
@media (min-width: 992px) {
  /** Top Nav */
  nav#top-nav {
    border-bottom: 1px solid rgb(165 157 157 / 16%);
  }
  nav#top-nav ul li.nav-item {
    padding: 0px 10px;
  }
  nav#top-nav ul li.nav-item:not(:last-child) {
    border-right: 1px solid rgb(165 157 157 / 16%);
  }

  /** Bottom Nav */
  nav#bottom-nav {
    border-bottom: 2px solid rgb(165 157 157 / 15%);
  }
  nav#bottom-nav .navbar-left {
    /* padding-left: 55px; */
  }
  nav#bottom-nav .navbar-right {
    /* padding-right: 55px; */
  }

  /** Undisplay Mobile Nav */
  nav#mobile-nav {
    display: none;
  }
}

/** For Mobile Size Nav */
@media (max-width: 991px) {
  /** Mobile Nav */
  nav#mobile-nav {
    display: flex;
    border-bottom: 1px solid rgb(165 157 157 / 16%);
    max-height: 100vh;
    overflow: hidden;
  }
  nav#mobile-nav button {
    margin-top: 10px;
    /* margin-right: 0px; */
    margin-bottom: 10px;
    /* margin-left: 0px; */
    border: none;
    color: #000;
  }
  nav#mobile-nav #navbarSupportedContent1 {
    margin-left: -17px;
    margin-right: -26px;
    max-height: calc(100vh - 1rem - 50px);
    overflow-y: auto;
  }
  nav#mobile-nav #navbarSupportedContent1 li.nav-item {
    padding: 8px 15px;
    border-top: 1px solid #e5e5e5;
  }
  nav#mobile-nav #navbarSupportedContent1 li.nav-item:last-child {
    padding-top: 15px;
  }
  nav#mobile-nav
    #navbarSupportedContent1
    li.nav-item
    .form-inline
    input[type="search"] {
    width: 100%;
  }

  /** Undisplay Top Nav */
  nav#top-nav {
    display: none;
  }
  /** Undisplay Bottom Nav */
  nav#bottom-nav {
    display: none;
  }

  nav .text-after-logo {
    font-size: 12px;
    margin-left: 10px;
  }

  body.navbar-collapsed {
    overflow: hidden;
  }
}

.text-cap {
  text-transform: capitalize;
}
</style>